import AnchorInfoModal from '@/compnents/anchorInfo';
import { Button, Form, Input, InputNumber, Modal, Space, Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { MainSubStructure } from '../../../../utils';
import type AlternativeSliceModel from './model';

const AlternativeSliceModal = observer(({ store }: { store: AlternativeSliceModel }) => {
  const { isOpen, onCancel, anchorInfoModel } = store;
  const {
    mainSubStructureModel,
    titleInfo: { name, slicePeopleCount, sliceCount },
  } = store;
  return (
    <>
      <Modal
        title="备选切片"
        centered={true}
        closable={true}
        open={isOpen}
        onCancel={onCancel}
        width="60%"
        footer={null}
      >
        <div>
          <span className="mr-2 font-600">雇主昵称：</span>
          {name}
        </div>
        <div>
          <span className="mr-2 font-600">备选信息：</span>
          {slicePeopleCount}人(共{sliceCount}切片)
        </div>
        <div style={{ height: '500px' }}>
          <MainSubStructure store={mainSubStructureModel} />
        </div>
      </Modal>
      <AnchorInfoModal store={anchorInfoModel} />
    </>
  );
});

export { AlternativeSliceModal };
